import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/Seo';
import breakpoints from '../styles/breakpoints';

const ContactArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  background: white;

  ${breakpoints.desktop} {
    grid-column: 3 / span 8;
  }
`;

const TextStyles = `
  font-family: BodoniSvtyTwoSCITCTT-Book;
  font-size: 16px;
  font-weight: normal;
  color: #000000;
  letter-spacing: 2px;
`;

const Title = styled.h2`
  ${TextStyles}
  margin: 20px 0;
`;

const Separator = styled.hr`
  width: 100%;
  max-width: 900px;
  height: 1px;
  border: none;
  background: 1px black;
  margin: 0px;

  ${breakpoints.desktop} {
    margin: 20px;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px;

  ${breakpoints.desktop} {
    margin: 0 0 30px;
  }
`;

const Link = styled.a.attrs(({ to }) => ({
  href: to,
  target: '_blank',
}))`
  ${TextStyles}
  text-align: center;
  text-decoration: none;
`;

const LongRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  margin: 30px 0 0;
`;

const SecondPage = () => (
  <Layout>
    <SEO title="Contact" />
    <ContactArea>
      <Title>Previa cita</Title>
      <Separator />
      <Info>
        <Title>Teléfono</Title>
        <Link to="tel:+52818-078-6824">+52 818-078-6824</Link>
      </Info>
      <LongRow>
        <Info>
          <Title>Monterrey NL.</Title>
        </Info>
        <Info>
          <Title>Montemorelos NL.</Title>
        </Info>
      </LongRow>
      <Info>
        <Title>Información y cotización:</Title>
        <Link to="mailto:hola@gramatos.com">hola@gramatos.com</Link>
      </Info>
      <LongRow>
        <Info>
          <Title>Instagram</Title>
          <Link to="https://www.instagram.com/thegramatos/">@thegramatos</Link>
        </Info>
        <Info>
          <Title>Facebook</Title>
          <Link to="https://www.facebook.com/gramatos">@gramatos</Link>
        </Info>
      </LongRow>
    </ContactArea>
  </Layout>
);

export default SecondPage;
